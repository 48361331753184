.ag-theme-alpine {
  --ag-row-hover-color: #b1d9fa8e;
  --ag-border-color: transparent;
  --ag-header-background-color: #d0eae4;
  --ag-header-column-resize-handle-color: #000000;
  --ag-range-selection-border-color: rgba(0, 0, 0, 0);
  --ag-selected-row-background-color: #b1d9fa8e;
}

.ag-theme-alpine .ag-root-wrapper {
  /* border: 1px red solid; */
}

.ag-theme-alpine .ag-root.ag-layout-auto-height {
  /* position: relative; */
  /* border: 1px blue solid; */
  /* height: 100%; */
}

.ag-theme-alpine .ag-row-even {
  border-bottom-color: rgba(0, 0, 0, 0.02);
  background-color: #00000007;
}

.ag-theme-alpine .ag-row-odd {
  border-bottom-color: rgba(0, 0, 0, 0.02);
}

.ag-theme-alpine .ag-cell {
  display: flex;
  align-items: center;
  /* border-right: 1px red solid; */
}

.ag-theme-alpine .row-hover:hover {
  cursor: pointer;
}

.ag-theme-alpine .ag-header-cell.text-center .ag-header-cell-label {
  justify-content: center;
}

.ag-theme-alpine .ag-header-cell.text-right .ag-header-cell-label {
  justify-content: right;
}

.ag-theme-alpine .ag-cell.border-right {
  border-right: 1px #dde2eb solid;
}

/* .ag-theme-alpine .ag-header {
  position: sticky;
  top: 100px;
  border: 1px red solid;
  z-index: 1;
} */
